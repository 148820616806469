<template>
  <div class="box">
    <div class="boxClass">
      <div class="headClass">{{ form.title }}</div>
      <div class="contentClass">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :labelCol="{ style: 'width: 150px' }"
          :wrapper-col="{ span: 15 }"
          :rules="rules"
        >
          <a-form-model-item label="模块类型" prop="configType">
            <a-radio-group name="radioGroup" v-model="form.configType" :disabled='disable'>
              <a-radio :value="0"> 自定义 </a-radio>
              <a-radio :value="1"> 独立系统配置 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="form.configType == 1" label="android" prop="android" required>
            <a-radio-group name="radioGroup" v-model="form.android" :disabled='disable'>
              <a-radio value="SDK"> SDK </a-radio>
              <a-radio value="url">
                链接
                <a-input v-if="form.android === 'url'" placeholder="请填写链接地址" v-model.trim="form.androidUrl" :disabled='disable'/>
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="form.configType == 1" label="ios" prop="ios" required>
            <a-radio-group name="radioGroup" v-model="form.ios" :disabled='disable'>
              <a-radio value="SDK"> SDK </a-radio>
              <a-radio value="url">
                链接
                <a-input v-if="form.ios === 'url'" placeholder="请填写链接地址" v-model.trim="form.iosUrl" :disabled='disable'/>
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="模块类型" prop="pageType">
            <a-radio-group @change="radioChange" name="radioGroup" v-model="form.pageType" :disabled='disable'>
              <a-radio :value="0"> 原生页面 </a-radio>
              <a-radio :value="1"> H5链接 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="form.pageType === 0" label="选择页面" prop="serviceId">
            <a-select
              :disabled='disable'
              v-model="form.serviceId"
              placeholder="请选择"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option v-for="option in serviceList" :key="option.serviceNumber" :value="option.serviceNumber">
                {{ option.serviceName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="form.pageType === 1" label="链接地址" prop="h5Url">
            <a-input placeholder="请填写链接地址" v-model.trim="form.h5Url" :disabled='disable'/>
          </a-form-model-item>
          <a-form-model-item label="添加图片" prop="pageImage">
            <a-upload
              :disabled="disable"
              name="file"
              list-type="picture-card"
              :action="IMG_API + '/oss/files'"
              class="avatar-uploader"
              :file-list="form.pageImage"
              @change="handleChangeImg"
              @preview="handlePreview"
              :before-upload="imgBeforeUpload"
            >
              <div v-if="this.form.pageImage.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tip">请上传尺寸{{ form.width }}*{{ form.height }}，格式为PNG、JPG、GIF的图片</p>
          </a-form-model-item>
          <a-form-model-item label="图片链接">
            <a-input v-model.trim="form.imgUrl" placeholder="请输入图片链接" @blur="imgBlur()" :disabled='disable'/>
          </a-form-model-item>
          <a-form-model-item label="页面名称" prop="pageName">
            <a-input placeholder="请输入页面名称" v-model.trim="form.pageName" :disabled='disable'/>
          </a-form-model-item>
          <a-form-model-item label="用户身份限制：">
            <a-radio-group name="radioGroup" v-model="form.userIdentity" :disabled='disable'>
              <a-radio :value="0"> 无限制 </a-radio>
              <a-radio :value="1"> 企业认证 </a-radio>
              <a-radio :value="2"> 权益企业认证 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="企业认证人群限制：">
            <a-checkbox-group v-model="form.enterpriseUser" :options="authenticationPeople" :disabled='disable'/>
          </a-form-model-item>
          <a-form-model-item label="页面状态：" prop="pageStatus">
            <a-radio-group name="radioGroup" v-model="form.pageStatus" :disabled='disable'>
              <a-radio :value="0"> 启用 </a-radio>
              <a-radio :value="1"> 暂停 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="form.pageStatus === 1" label="提示语" prop="prompt">
            <a-input placeholder="请填写提示语" v-model.trim="form.prompt" :disabled='disable'/>
          </a-form-model-item>

        </a-form-model>
      </div>
      <a-modal :visible="previewVisible" :footer="null" @cancel="previewHandleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </div>
</template>
<script>
import { IMG_API } from "@/config/index";
export default {
  props: ["form", "serviceList", "authenticationPeople", "disable"],
  data() {
    let checkHttp = (rule, value, callback) => {
      // 校验http地址
      let regex =
        /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9\u4E00-\u9FA5.,?'\\+&%$#=~_-]+))*$/;

      if (regex.test(value)) {
        return callback();
      }
      // 不合法
      callback(new Error("格式不正确请重新输入"));
    };
    return {
      id: "",
      checkSize: false, //校验图片大小
      checkFormat: false, //校验格式
      IMG_API: IMG_API, //获取上传域名
      previewVisible: false, //预览弹窗开关
      previewImage: "", //预览图片地址
      rules: {
        configType: [{ required: true, message: "请选择模块类型", trigger: "blur" }],
        pageType: [{ required: true, message: "请选择模块类型", trigger: "blur" }],
        serviceId: [{ required: true, message: "请选择服务", trigger: "blur" }],
        h5Url: [
          { required: true, message: "请输入链接地址", trigger: "blur" },
          { validator: checkHttp, trigger: "blur" },
        ],
        pageImage: [{ required: true, message: "请选择图片", trigger: "change" }],
        pageStatus: [{ required: true, message: "请选择页面状态", trigger: "blur" }],
        prompt: [{ required: true, message: "请填写提示语", trigger: "blur" }],
        pageName: [{ required: true, message: "请填写页面名称", trigger: "blur" }],
      },
    };
  },
  mounted() {},
  methods: {
    // 模块类型change事件
    radioChange() {
      this.form.serviceId = null;
      this.form.h5Url = "";
      this.$refs.ruleForm.clearValidate();
    },

    imgBlur() {
      var that = this;
      if(that.form.imgUrl) {
        var data = [];
        var obj = {
          uid: "1",
          name: "image.png",
          status: "done",
          url: that.form.imgUrl,
        };
        data = [obj];
        this.form.pageImage = data;
      }
    },

    // //处理提交数据
    // dataProcessing(data) {
    //   let obj = {
    //     pageType: data.pageType,
    //     text: data.pageType === 0 ? data.serviceId : data.h5Url,
    //     id: data.id ? data.id : null,
    //     pageLocation: data.pageLocation,
    //     pageImage: data.pageImage[0].response ? data.pageImage[0].response.redirect_uri : data.pageImage[0].url,
    //     userIdentity: data.userIdentity,
    //     enterpriseUser: data.enterpriseUser,
    //     pageStatus: data.pageStatus,
    //     prompt: data.prompt,
    //     pageName: data.pageName,
    //     configType: data.configType,
    //     android: data.android === 'SDK' ? 'SDK' : data.androidUrl,
    //     ios: data.ios === 'SDK' ? 'SDK' : data.iosUrl
    //   };
    //   return obj;
    //
    // },
    //基础信息校验规则
    parentHandleclick() {
      this.$refs.ruleForm.validate((valid) => {
        let flag = false;
        if (valid) {
          flag = true
          //往父组件赋值
          this.$emit("contentMessage", flag);
        } else {
          flag = false
          //往父组件赋值
          this.$emit("contentMessage", flag);
        }
      });
    },
    // 清空规则
    clearRules() {
      this.$refs.ruleForm.clearValidate();
    },
    //校验图片
    handleChangeImg({ fileList }) {
      if (this.checkSize) {
        this.form.pageImage = fileList;
        console.log(this.form);
        if(this.form.pageImage.length > 0 && this.form.pageImage[0].response) {
          this.form.imgUrl = this.form.pageImage[0].response.redirect_uri
        } else {
          this.form.imgUrl = '';
        }
      } else {
        this.form.pageImage = [];
      }
    },
    //上传文件之前校验图片大小
    imgBeforeUpload(file) {
      this.checkSize = false;
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === "png" || type === "jpg" || type === "gif";
      if (!isJPG) {
        this.$message.error("上传图片只能是jpg、png、gif格式!");
        return false;
      }
      const width = this.form.width; // 获取图片的限制宽度
      const height = this.form.height; //获取图片的限制高度
      this.checkSize = isJPG;
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          this.checkSize = false;
          return reject();
        }
      );
      return isJPG && isSize;
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    //预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    //弹窗图片预览关闭弹窗
    previewHandleCancel() {
      this.previewVisible = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-form-item {
  display: flex;
}
::v-deep .ant-form-item-control-wrapper {
  flex: 1;
}
.boxClass {
  border: 1px solid #e8e8e8;
}
.headClass {
  border-bottom: 1px solid #e8e8e8;
  line-height: 40px;
  text-indent: 20px;
}
.contentClass {
  padding: 20px 40px 0 40px;
}
.tip {
  font-size: 12px;
  line-height: 20px;
}
.box {
  padding-right: 20px;
}

::v-deep .ant-checkbox-group {
  width: 100%;
  padding-top: 8px;
  .ant-checkbox-group-item {
    width: 18%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
