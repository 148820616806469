/**
 * 宫格类型
 * @type {{}}
 */
// 一排2宫格型
 export const gridTypeOne = [{
    title: "左图", //子组件标题
    pageLocation: 0,
    width: 340, //图片宽度
    height: 340, //图片高度
    pageType: 0, // 模块类型
    serviceId: "", //服务ID
    h5Url: "", //H5链接
    pageImage: [], //图片路径
    userIdentity: 1, // 用户身份限制
    enterpriseUser: [], // 企业认证人群限制
    pageStatus: 1, // 页面状态
    prompt: "", // 提示语
    pageName: "",
    imgUrl: "",
    configType: 0,
    android: 'SDK',
    ios: 'SDK',
    androidUrl: '',
    iosUrl: '',
  },{
    title: "右图", //子组件标题
    pageLocation: 1,
    width: 340, //图片宽度
    height: 340, //图片高度
    pageType: 0, // 模块类型
    serviceId: "", //服务ID
    h5Url: "", //H5链接
    pageImage: [], //图片路径
    userIdentity: 1, // 用户身份限制
    enterpriseUser: [], // 企业认证人群限制
    pageStatus: 1, // 页面状态
    prompt: "", // 提示语
    pageName: "",
    imgUrl: "",
    configType: 0,
    android: 'SDK',
    ios: 'SDK',
    androidUrl: '',
    iosUrl: '',
}]

export const gridTypeTwo =  [{
    title: "左图", //子组件标题
    gridType: 1,
    pageLocation: 0,
    width: 220, //图片宽度
    height: 340, //图片高度
    pageType: 0, // 模块类型
    serviceId: "", //服务ID
    h5Url: "", //H5链接
    pageImage: [], //图片路径
    userIdentity: 1, // 用户身份限制
    enterpriseUser: [], // 企业认证人群限制
    pageStatus: 1, // 页面状态
    prompt: "", // 提示语
    pageName: "",
    imgUrl: "",
    configType: 0,
    android: 'SDK',
    ios: 'SDK',
    androidUrl: '',
    iosUrl: '',
   },{
    title: "中图", //子组件标题
    pageLocation: 1,
    width: 220, //图片宽度
    height: 340, //图片高度
    pageType: 0, // 模块类型
    serviceId: "", //服务ID
    h5Url: "", //H5链接
    pageImage: [], //图片路径
    userIdentity: 1, // 用户身份限制
    enterpriseUser: [], // 企业认证人群限制
    pageStatus: 1, // 页面状态
    prompt: "", // 提示语
    pageName: "",
    imgUrl: "",
    configType: 0,
    android: 'SDK',
    ios: 'SDK',
    androidUrl: '',
    iosUrl: '',
  },
  {
    title: "右图", //子组件标题
    pageLocation: 1,
    width: 220, //图片宽度
    height: 340, //图片高度
    pageType: 0, // 模块类型
    serviceId: "", //服务ID
    h5Url: "", //H5链接
    pageImage: [], //图片路径
    userIdentity: 1, // 用户身份限制
    enterpriseUser: [], // 企业认证人群限制
    pageStatus: 1, // 页面状态
    prompt: "", // 提示语
    pageName: "",
    imgUrl: "",
    configType: 0,
    android: 'SDK',
    ios: 'SDK',
    androidUrl: '',
    iosUrl: '',
  }]

export  const gridTypes =  [
  {
    label: "一排2宫格型",
    value: 1
  },
  {
    label: "一排3宫格型",
    value: 2
  },
  {
    label: "组合型宫格（左小右大）",
    value: 3
  },
  {
    label: "组合型宫格（左大右小）",
    value: 4
  }
]

export default {
  gridTypeOne,
  gridTypeTwo,
  gridTypes
}
